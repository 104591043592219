import { useQuery } from '@apollo/client';
import { endOfMonth, startOfMonth, subYears } from 'date-fns';

import { GET_COMPANY_INFO } from '@/manager-graphql';

export const useHome = () => {
  const currentDate = new Date();
  const until = endOfMonth(currentDate).toISOString();
  const since = startOfMonth(subYears(currentDate, 10)).toISOString();

  const { data, loading } = useQuery(GET_COMPANY_INFO, {
    variables: {
      insightsCountInput: {
        since,
        until,
      },
    },
  });

  return {
    companyInfo: data?.companyInfo,
    isLoading: loading,
  };
};
