import { ApiDocPage, GET_API_DOCS } from '@/manager-graphql';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

export const useApiAccessDocs = () => {
  const { data, loading, error } = useQuery(GET_API_DOCS);
  const [currentPage, setCurrentPage] = useState<ApiDocPage>();

  const handlePageChange = (pageId: string) => {
    data?.apiDoc?.pages.forEach((page) => {
      if (page.id === pageId) {
        setCurrentPage(() => page);
      }
    });
  };

  useEffect(() => {
    if (data?.apiDoc?.pages) {
      setCurrentPage(() => data.apiDoc.pages[0]);
    }
  }, [data]);

  return {
    currentPage,
    apiDocs: data?.apiDoc,
    loading,
    error: error?.message,
    handlePageChange,
  };
};
