import {
  faArrowDownToLine,
  faCheck,
  faFile,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

import { DownloadStatus, type GetDownloadsQuery } from '@/manager-graphql';
import { useCancelDownload } from './insight-list-downloads-item.hooks';

interface Props {
  download: GetDownloadsQuery['downloads'][number];
}

function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

const InsightListDownloadsItem = forwardRef<HTMLButtonElement, Props>(
  ({ download: { id, createdAt, size, url, status } }, forwardedRef) => {
    const [cancelDownload] = useCancelDownload();
    const reportDate = new Date(createdAt)
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');
    const fileName = `insights-report-${reportDate}`;

    const downloadFileAtURL = (url: string) => {
      const aTag = document.createElement('a');
      aTag.href = url;
      aTag.setAttribute('download', fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    };

    const isComplete = status === DownloadStatus.Complete;
    const isError = new Set([
      DownloadStatus.Canceled,
      DownloadStatus.Error,
    ]).has(status);
    const isPending = new Set([
      DownloadStatus.Pending,
      DownloadStatus.Processing,
    ]).has(status);

    const handleOnCLick = url
      ? () => downloadFileAtURL(url)
      : isPending
      ? () => cancelDownload({ variables: { id } })
      : undefined;

    return (
      <button
        className="group inline-flex h-14 flex-col items-start justify-center gap-3 rounded bg-neutral-50 p-3 active:hover:bg-neutral-100 disabled:cursor-not-allowed"
        disabled={!handleOnCLick}
        onClick={handleOnCLick}
        ref={forwardedRef}
      >
        <div className="inline-flex items-start justify-between self-stretch">
          <div className="flex min-h-9 items-start justify-start gap-3">
            <div
              data-status={status}
              className="flex h-5 w-5 items-center justify-center rounded-full text-gray-500 data-[status=Canceled]:bg-rose-100 data-[status=Complete]:bg-lime-100 data-[status=Error]:bg-rose-100 data-[status=Canceled]:text-rose-800 data-[status=Complete]:text-emerald-800 data-[status=Error]:text-rose-800"
            >
              <FontAwesomeIcon
                className="text-sm"
                icon={isComplete ? faCheck : isError ? faXmark : faFile}
              />
            </div>

            <div className="inline-flex flex-col items-start justify-center">
              <div className="max-w-64 truncate text-sm font-medium leading-tight text-zinc-800">
                {fileName}
              </div>
              <div className="text-xs font-normal leading-none tracking-tight text-gray-500">
                {size ? bytesToSize(size) : status}
              </div>
            </div>
          </div>

          {isComplete ? (
            <div className="inline-flex h-5 w-5 items-center justify-center text-gray-400 transition-colors group-hover:text-gray-600">
              <FontAwesomeIcon icon={faArrowDownToLine} />
            </div>
          ) : null}
          {isPending ? (
            <div className="inline-flex h-5 w-5 items-center justify-center text-rose-800 transition-colors group-hover:text-rose-900">
              <FontAwesomeIcon icon={faXmark} />
            </div>
          ) : null}
        </div>
      </button>
    );
  }
);

export default InsightListDownloadsItem;
