import { useMutation } from '@apollo/client';
import { useState } from 'react';

import { ADD_SURVEY, type GetQuestionsQuery } from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useMeasurement } from '@/measurement';
import {
  getHTMLEmbedCode,
  getHTMLiFrameCode,
  getSurveyUrl,
  getWidgetCode,
} from './questions-generate-links-drawer.helpers';

interface Props {
  question: GetQuestionsQuery['listQuestions']['items'][number];
}

export function useQuestionsGenerateLinksDrawer({
  question: { id: questionId, primaryQuestion, ratings, tags },
}: Props) {
  const [open, setOpen] = useState(false);
  const [addSurvey] = useMutation(ADD_SURVEY);
  const [isSubmitting, setSubmitting] = useState<boolean>();
  const [surveyId, setSurveyId] = useState<string>();
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const measurement = useMeasurement();
  const { addToast } = useToast();

  const handleCopyHTML = async () => {
    if (surveyId) {
      try {
        await navigator.clipboard.writeText(
          await getHTMLEmbedCode(surveyId, primaryQuestion, ratings)
        );
        addToast({ title: 'HTML copied successfully!', type: 'success' });
        measurement?.track('Survey link copied', { format: 'HTML Embed code' });
      } catch (e) {
        addToast({
          title: 'Failed to generate Survey for HTML',
          type: 'error',
        });
      }
    }
  };

  const handleCopyWidget = async () => {
    try {
      if (surveyId) {
        await navigator.clipboard.writeText(getWidgetCode(surveyId));
        addToast({ title: 'Widget copied successfully!', type: 'success' });
        measurement?.track('Survey link copied', { format: 'Widget code' });
      } else {
        throw new Error('Failed to generate Survey for Widget');
      }
    } catch (e) {
      addToast({
        title: 'Failed to generate Survey for Widget',
        type: 'error',
      });
    }
  };

  const handleCopyIFrame = async () => {
    try {
      if (surveyId) {
        await navigator.clipboard.writeText(
          getHTMLiFrameCode(
            surveyId,
            tags?.some((t) => t.name?.toLowerCase()?.trim() === 'nps')
          )
        );
        addToast({ title: 'iFrame copied successfully!', type: 'success' });
        measurement?.track('Survey link copied', {
          format: 'iFrame Embed code',
        });
      } else {
        throw new Error('Failed to generate Survey for iFrame');
      }
    } catch (e) {
      addToast({
        title: 'Failed to generate Survey for iFrame',
        type: 'error',
      });
    }
  };

  const handleCopyShareableLink = async () => {
    try {
      if (surveyId) {
        await navigator.clipboard.writeText(getSurveyUrl(surveyId));
        addToast({
          title: 'Shareable Link copied successfully!',
          type: 'success',
        });
        measurement?.track('Survey link copied', { format: 'Shareable Link' });
      } else {
        throw new Error('Failed to generate Survey for Shareable Link');
      }
    } catch (e) {
      addToast({
        title: 'Failed to generate Survey for Shareable Link',
        type: 'error',
      });
    }
  };

  const handleGenerateQrCode = async () => {
    try {
      if (surveyId) {
        setQrCodeUrl(getSurveyUrl(surveyId));
        measurement?.track('Survey link copied', { format: 'QR Code' });
      } else {
        throw new Error('Failed to generate Survey for QR Code');
      }
    } catch (e) {
      addToast({
        title: 'Failed to generate Survey for QR Code',
        type: 'error',
      });
    }
  };

  const handleCloseQrCodeDialog = () => {
    setQrCodeUrl(() => '');
  };

  const handleDrawerOpenChange = (value: boolean) => {
    setOpen(value);
    if (questionId && !surveyId && value) {
      setSubmitting(() => true);
      addSurvey({ variables: { input: { questionId } } })
        .then(({ data }) => {
          if (data?.addSurvey?.id) {
            setSurveyId(() => data?.addSurvey?.id);
          } else {
            setOpen(false);
            addToast({
              title: 'Failed to generate Survey',
              type: 'error',
            });
          }
        })
        .finally(() => {
          setSubmitting(() => false);
        });
    }
  };

  return {
    isSubmitting,
    qrCodeUrl,
    open,
    onCloseQrCodeDialog: handleCloseQrCodeDialog,
    onCopyHTML: handleCopyHTML,
    onCopyIFrame: handleCopyIFrame,
    onCopyShareableLink: handleCopyShareableLink,
    onCopyWidget: handleCopyWidget,
    onGenerateQrCode: handleGenerateQrCode,
    onOpenChange: handleDrawerOpenChange,
  };
}
