import { useToast } from '@/manager-toast';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/shared-ui';
import { faSpinner, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { useState } from 'react';

interface Props {
  insightId: string;
  onDelete?: (insightId: string) => Promise<void>;
}

export default function DeleteInsight({ insightId, onDelete }: Props) {
  const { addToast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete?.(insightId);
      addToast({
        title: 'Insight deleted',
        type: 'success',
      });
    } catch (e) {
      addToast({
        title: 'Error deleting insight',
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button color="text" size="md">
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="font-display px-8 py-2 pt-8 text-2xl font-semibold leading-tight">
          Delete insight?
        </DialogTitle>
        <DialogDescription className="px-8 py-2 text-sm text-gray-400">
          This action cannot be undone and will permanently remove this insight
          and all associated data.
        </DialogDescription>

        <div className="px-8 py-4 text-sm text-gray-400">
          Are you sure you want to delete this insight?
        </div>

        <div className="flex items-center justify-between border-t px-8 py-4">
          <div />

          <div className="flex items-center gap-1">
            <DialogClose asChild>
              <Button color="text">Cancel</Button>
            </DialogClose>
            <Button onClick={handleDelete} disabled={isDeleting}>
              {isDeleting ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Delete'
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
