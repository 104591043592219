import { useQuery } from '@apollo/client';

import {
  GET_QUESTIONS_WITH_STATS,
  OrderDirection,
  QuestionOrderField,
} from '@/manager-graphql';

export function useInsightList() {
  const { data, error, loading, fetchMore } = useQuery(
    GET_QUESTIONS_WITH_STATS,
    {
      variables: {
        orderBy: {
          direction: OrderDirection.Desc,
          field: QuestionOrderField.Messages,
        },
      },
    }
  );

  const handleLoadMore = () => {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          questions: {
            ...fetchMoreResult.questions,
            items: [
              ...prev.questions.items,
              ...fetchMoreResult.questions.items,
            ],
          },
        };
      },
      variables: {
        after: data?.questions.pageInfo.endCursor,
        orderBy: {
          direction: OrderDirection.Desc,
          field: QuestionOrderField.Messages,
        },
      },
    });
  };

  return {
    error: error?.message,
    isLoading: loading,
    questions:
      data?.questions.items?.filter(
        (f) => f?.totalInsights && f?.totalInsights > 0
      ) ?? [],
    onLoadMore: data?.questions.pageInfo.hasNextPage
      ? handleLoadMore
      : undefined,
  };
}
