import { Navigate } from 'react-router-dom';

import Layout from '@/manager-sidebar';
import { StateMessageLoading } from '@/shared-ui';
import { useHome } from './home.hooks';

export default function Home() {
  const { companyInfo, isLoading } = useHome();

  if (!isLoading) {
    if (companyInfo?.insightsCount) {
      return <Navigate replace to={'dashboard'} />;
    } else {
      return <Navigate replace to={'onboarding'} />;
    }
  }

  return (
    <Layout>
      <div className="w-full bg-white">
        <main className="flex h-full items-center justify-center px-10 py-6">
          <div className="flex max-w-lg flex-col gap-11">
            {isLoading && <StateMessageLoading />}
          </div>
        </main>
      </div>
    </Layout>
  );
}
