import React from 'react';
import {
  UseFormWatch,
  UseFormSetValue,
  UseFormClearErrors,
  FieldErrors,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import { CompanyInfoFormValues } from './manger-onboarding.helpers';

interface ButtonGroupProps {
  options: string[];
  fieldName: 'numberOfPeople' | 'tryingFor';
  label: string;
  watch: UseFormWatch<CompanyInfoFormValues>;
  setValue: UseFormSetValue<CompanyInfoFormValues>;
  clearErrors: UseFormClearErrors<CompanyInfoFormValues>;
  errors: FieldErrors<CompanyInfoFormValues>;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
  isSubmitting: boolean;
  isCompanyInfoLoading: boolean;
  saveToLocalStorage: (data: Partial<CompanyInfoFormValues>) => void;
}

export const ManagerOnboardingComponentInfoButtonGroup: React.FC<
  ButtonGroupProps
> = ({
  options,
  fieldName,
  label,
  watch,
  setValue,
  clearErrors,
  errors,
  showErrors,
  setShowErrors,
  isSubmitting,
  isCompanyInfoLoading,
  saveToLocalStorage,
}) => {
  const fieldValue = watch(fieldName);
  const hasError = showErrors && !!errors[fieldName]?.message && !fieldValue;

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm font-semibold leading-tight text-gray-600">
        {label}
      </span>
      <div className="inline-flex flex-wrap items-start justify-start gap-2">
        {options.map((option) => (
          <button
            key={option}
            type="button"
            onClick={() => {
              setValue(fieldName, option, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
              clearErrors(fieldName);
              setShowErrors(true);
              saveToLocalStorage({ [fieldName]: option });
            }}
            className={twMerge(
              classNames(
                'inline-flex h-12 items-center justify-center gap-2 rounded-lg border bg-gray-50 px-4 py-2 text-center text-base font-normal leading-normal tracking-tight text-gray-800 transition-all duration-200 ease-in-out',
                {
                  'border-gray-800': fieldValue === option,
                  'border-red-500': hasError,
                  'border-gray-200': !hasError && fieldValue !== option,
                  'cursor-not-allowed opacity-50': isSubmitting,
                }
              )
            )}
            disabled={isSubmitting || isCompanyInfoLoading}
          >
            {option}
          </button>
        ))}
      </div>
      {hasError && (
        <div className="text-sm text-red-600">{errors[fieldName]?.message}</div>
      )}
    </div>
  );
};
