import { faPlus, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useFieldArray,
  type Control,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';

import { Button } from '@/shared-ui';

import { type UpdateCompanyInfoFormValues } from './company-summary-form.helpers';

interface Props {
  control: Control;
  errors: FieldErrors<UpdateCompanyInfoFormValues>['productServices'];
  fieldName: 'features' | 'benefits' | 'uniqueSellingPoints';
  index: number;
  register: UseFormRegister<UpdateCompanyInfoFormValues>;
}

const definitionTitles = {
  features: 'Features',
  benefits: 'Benefits',
  uniqueSellingPoints: 'Unique Selling Points',
} as const;

export default function CompanySummaryProductServicesFormSection({
  control,
  errors,
  fieldName,
  index: parentIndex,
  register,
}: Props) {
  const parentName = `productServices.${parentIndex}.${fieldName}` as const;
  const { fields, append, remove } = useFieldArray({
    control,
    name: parentName,
    rules: { minLength: 1 },
  });

  return (
    <div className="space-y-2 rounded-lg border bg-white p-4">
      <h5 className="text-sm font-medium leading-normal text-zinc-800">
        {definitionTitles[fieldName]}
      </h5>

      {fields.map((field, index) => (
        <div key={field.id} className="flex gap-2">
          <input
            data-invalid={
              !!errors?.[parentIndex]?.[fieldName]?.[index]?.message
            }
            className="w-full rounded border px-2 py-1 text-sm leading-normal text-zinc-800 focus:outline-none data-[invalid=true]:border-red-500"
            id={`${parentName}.${index}`}
            {...register(`${parentName}.${index}`)}
          />
          <button
            className="flex h-8 w-8 items-center justify-center rounded border bg-white text-zinc-500 transition-colors hover:bg-zinc-100 "
            onClick={() => remove(index)}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </button>
        </div>
      ))}

      <Button color="text" onClick={() => append('')}>
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Add
      </Button>
    </div>
  );
}
