import Layout from '@/manager-sidebar';
import { Navigate } from 'react-router-dom';
import { useOnboarding } from './manager-onboarding.hooks';
import { StateMessageLoading } from '@/shared-ui';

export default function Onboarding() {
  const { companyInfo, isLoading } = useOnboarding();

  if (!isLoading) {
    if (companyInfo) {
      return <Navigate replace to={'questions'} />;
    } else {
      return <Navigate replace to={'company-info'} />;
    }
  }

  return (
    <Layout>
      <div className="w-full bg-white">
        <main className="flex h-full items-center justify-center px-10 py-6">
          <div className="flex max-w-lg flex-col gap-11">
            {isLoading && <StateMessageLoading />}
          </div>
        </main>
      </div>
    </Layout>
  );
}
