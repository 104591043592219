import { useMutation, useQuery } from '@apollo/client';
import { addDays, subMonths } from 'date-fns';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

import {
  GENERATE_DASHBOARD_SUMMARY,
  GET_DASHBOARD,
  GetDashboardDocument,
} from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useMeasurement } from '@/measurement';

const dashboardFilterState = atom({
  key: 'dashboardFilter',
  default: {
    since: subMonths(new Date(), 12),
    until: addDays(new Date(), 1),
  },
});

export const useGenerateDashboardSummary = () =>
  useMutation(GENERATE_DASHBOARD_SUMMARY);

export function useDashboard() {
  const [filter] = useRecoilState(dashboardFilterState);
  const measurement = useMeasurement();
  const { addToast } = useToast();
  const {
    data: dashboardData,
    error: dashboardError,
    loading: loadingDashboard,
  } = useQuery(GET_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    variables: { filter },
  });
  const [generateSummary, { error: summaryError, loading: loadingSummary }] =
    useGenerateDashboardSummary();
  const handleGenerateSummary = useCallback(() => {
    generateSummary({
      variables: { input: filter },
      update: (cache, { data }) => {
        if (dashboardData && data?.generateDashboardSummary) {
          cache.writeQuery({
            query: GetDashboardDocument,
            variables: { filter },
            data: {
              dashboard: {
                ...dashboardData.dashboard,
                summary: data.generateDashboardSummary,
              },
            },
          });
        } else if (dashboardData && data?.generateDashboardSummary === null) {
          addToast({
            title: 'No summary yet',
            type: 'info',
          });
        }
        measurement?.track('Generate Summary clicked');
      },
    });
  }, [generateSummary, filter, dashboardData, addToast, measurement]);

  return {
    dashboard: dashboardData?.dashboard,
    error: dashboardError?.message || summaryError?.message,
    loadingDashboard,
    loadingSummary,
    onGenerateSummary: handleGenerateSummary,
  };
}
