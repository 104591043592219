import { GET_INSIGHTS_LIGHT_WEIGHT } from '@/manager-graphql';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function useOnboardingSurvey() {
  const { id: surveyId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const questionId = location.state?.questionId ?? null;

  const { data, startPolling, stopPolling } = useQuery(
    GET_INSIGHTS_LIGHT_WEIGHT,
    {
      variables: { filter: { questionId } },
      skip: !questionId || isButtonEnabled,
    }
  );

  useEffect(() => {
    if (questionId && surveyId) {
      startPolling(1000);
    }
    return stopPolling;
  }, [questionId, surveyId, startPolling, stopPolling]);

  useEffect(() => {
    if (data?.listInsights?.items?.length) {
      setIsButtonEnabled(true);
      stopPolling();
    }
  }, [data, stopPolling]);

  const handleButtonClick = useCallback(() => {
    if (isButtonEnabled) {
      navigate('/questions');
    }
  }, [isButtonEnabled, navigate]);

  const getSurveyUrl = useCallback((id: string) => {
    const host = window.location.origin;
    return `${host}/survey/manager-embed/${id}`;
  }, []);

  return {
    surveyId,
    isButtonEnabled,
    handleButtonClick,
    getSurveyUrl,
    questionId,
    survey: location?.state,
  };
}
