import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Chip } from '@/shared-ui';
import { useSidebar } from './manager-sidebar.hooks';

interface SidebarItemProps {
  icon: IconDefinition;
  text: React.ReactNode;
  to: React.ComponentProps<typeof NavLink>['to'];
  alert?: number | null;
}

export default function SidebarItem({
  icon,
  text,
  to,
  alert,
}: SidebarItemProps) {
  const { sidebarState } = useSidebar();

  return (
    <li>
      <NavLink
        to={to}
        data-state={sidebarState}
        className={({ isActive }) =>
          twMerge(
            'relative flex h-10 w-10 items-center rounded-md transition-colors group-hover/sidebar:w-full data-[state=fixed]:w-full',
            isActive
              ? 'bg-zinc-700 text-white'
              : 'text-zinc-400 hover:bg-zinc-50'
          )
        }
      >
        <span className="absolute w-10 text-center">
          <FontAwesomeIcon icon={icon} size="lg" />
        </span>

        <span
          data-state={sidebarState}
          className="ml-10 w-0 overflow-hidden font-medium tracking-wide group-hover/sidebar:inline group-hover/sidebar:w-40 data-[state=fixed]:inline data-[state=fixed]:w-40"
        >
          {text}
        </span>

        {typeof alert === 'number' ? (
          <div
            data-state={sidebarState}
            className="absolute right-1 top-1 group-hover/sidebar:right-4 group-hover/sidebar:top-auto data-[state=fixed]:right-4 data-[state=fixed]:top-auto"
          >
            <Chip content={alert} />
          </div>
        ) : null}
      </NavLink>
    </li>
  );
}
