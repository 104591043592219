import { faPenLine } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import type {
  GetQuestionsQuery,
  UpdateQuestionMutationVariables,
} from '@/manager-graphql';

import {
  UpdateQuestionFormValues,
  updateQuestionSchema,
} from './questions-accordion-item.helpers';
import QuestionsGenerateLinksDrawer from './questions-generate-links-drawer';

const STANDARD_TAG_NAMES = ['ai', 'system', 'user', 'edited'];

interface QuestionsAccordionItemProps {
  onUpdateQuestion: (input: UpdateQuestionMutationVariables['input']) => void;
  question: GetQuestionsQuery['listQuestions']['items'][number];
}

export const QuestionsAccordionItem = ({
  onUpdateQuestion,
  question,
}: QuestionsAccordionItemProps) => {
  const [isEditing, setEditing] = useState(false);
  const {
    formState: { isSubmitSuccessful, isValid },
    handleSubmit,
    register,
    setFocus,
    getValues,
  } = useForm<UpdateQuestionFormValues>({
    defaultValues: question,
    resolver: zodResolver(updateQuestionSchema),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      setEditing(false);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (isEditing) {
      setFocus('primaryQuestion');
    }
  }, [isEditing, setFocus]);

  useEffect(() => {
    if (isEditing) {
      const handleEsc = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setEditing(false);
        }
      };
      window.addEventListener('keydown', handleEsc);
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }
  }, [isEditing]);

  function detectChangeAndHandleSubmit(e: React.BaseSyntheticEvent) {
    const values = getValues();
    if (values.primaryQuestion !== question.primaryQuestion) {
      handleSubmit(onUpdateQuestion)(e).then(() => {
        setEditing(false);
      });
    } else {
      setEditing(false);
    }
  }

  return (
    <li
      className="group/item flex items-center justify-between bg-white px-6 transition-colors last:rounded-b-md hover:bg-zinc-50 data-[invalid=true]:border-l-4 data-[invalid=true]:border-l-red-500"
      data-invalid={!isValid}
    >
      <div className="flex flex-grow items-center">
        {isEditing ? (
          <form
            onSubmit={detectChangeAndHandleSubmit}
            className="w-full"
            onBlur={detectChangeAndHandleSubmit}
          >
            <input type="submit" hidden />
            <input
              {...register('primaryQuestion')}
              className="w-full bg-transparent py-3 text-sm font-medium leading-normal text-zinc-800 outline-none"
              placeholder={question.primaryQuestion}
            />
          </form>
        ) : (
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <span className="py-3 text-sm font-medium leading-normal text-zinc-800">
                {question.primaryQuestion}
              </span>
            </div>

            <div className="align-baseline">
              {question.tags
                ?.filter((tag) => !STANDARD_TAG_NAMES.includes(tag.name))
                .map((tag) => (
                  <span
                    key={tag.id}
                    className="rounded bg-zinc-100 px-2 py-1 text-xs font-medium leading-tight text-zinc-600"
                  >
                    {tag.name}
                  </span>
                ))}
            </div>

            {question.totalInsights === 0 ? (
              <button
                className="group/edit invisible flex h-8 w-8 items-center justify-center rounded text-zinc-500 transition-colors hover:bg-zinc-100 hover:text-zinc-600 group-hover/item:visible"
                onClick={() => setEditing(true)}
              >
                <FontAwesomeIcon icon={faPenLine} />
              </button>
            ) : null}
          </div>
        )}
      </div>

      <QuestionsGenerateLinksDrawer question={question} />
    </li>
  );
};

export default QuestionsAccordionItem;
