import { BillingPlanCode } from '@/manager-graphql';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useUpgrade } from './manager-upgrade.hooks';

export function useUpgradeSidebar() {
  const { handleToggleUpgrade, hasReachedLimit, loading, planDetails } =
    useUpgrade();
  const isFreePlan = planDetails?.planCode === BillingPlanCode.Free;
  const isApiRoute = useLocation().pathname.includes('/api-access');

  const message = useMemo(() => {
    if (!loading && hasReachedLimit) {
      return "You've reached your monthly response limit.";
    }

    if (!loading && isFreePlan && isApiRoute) {
      return 'You must upgrade for API Access.';
    }

    return null;
  }, [hasReachedLimit, isFreePlan, isApiRoute, loading]);

  return {
    message,
    onUpgrade: handleToggleUpgrade,
  };
}
