import { useQuery } from '@apollo/client';

import { GET_COMPANY_INFO } from '@/manager-graphql';

export const useOnboarding = (poll?: boolean) => {
  const { data, loading } = useQuery(GET_COMPANY_INFO, {
    fetchPolicy: poll ? 'cache-and-network' : 'cache-first',
    pollInterval: poll ? 1000 : 0,
  });

  return {
    companyInfo: data?.companyInfo,
    isLoading: loading,
  };
};
