import { faPenLine } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Navigate } from 'react-router-dom';

import Layout from '@/manager-sidebar';
import {
  PageHeader,
  PageHeaderNav,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';
import CompanySummaryRefresh from './company-summary-refresh';
import { useCompanySummary } from './company-summary.hooks';

export default function CompanySummary() {
  const { companyInfo, error, loading, onCheckoutAIGeneratedQuestions } =
    useCompanySummary();

  if (error === 'Organization not found') {
    return <Navigate replace to={'..'} />;
  }

  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Theysaid AI Company Summary">
          <PageHeaderNav
            items={[
              { name: 'Dashboard', to: '/home/dashboard' },
              { name: 'Company Summary', to: '/home/company-summary' },
            ]}
          />
        </PageHeader>

        <main className="px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error || !companyInfo ? (
            <StateMessageError
              message={error || 'Failed to load company info'}
            />
          ) : (
            <div className="flex flex-col gap-6">
              <div className="flex gap-4">
                <div className="flex h-12 w-12 min-w-12 items-center justify-center rounded-full border border-zinc-100 bg-white">
                  <img
                    alt="Illustration of a stylized owl with large yellow eyes and gray feathers, perched on a red branch with a serious expression"
                    className="h-10 w-10 rounded-2xl"
                    src="/img/evo.png"
                  />
                </div>
                <div className="group rounded-r-lg border bg-white p-8">
                  <div className="flex items-baseline justify-between">
                    <h5 className="font-display text-xl font-semibold leading-tight">
                      This is how I understand your company:
                    </h5>
                    <div className="inline-flex gap-2">
                      <CompanySummaryRefresh />

                      <Link
                        className="invisible flex h-8 w-8 items-center justify-center rounded text-zinc-500 transition-colors hover:bg-zinc-100 group-hover:visible"
                        to="edit"
                      >
                        <FontAwesomeIcon icon={faPenLine} />
                      </Link>
                    </div>
                  </div>

                  <section className="mt-4">
                    <h6 className="font-semibold leading-normal">Company</h6>
                    <p className="mt-3 leading-normal text-zinc-500">
                      {companyInfo.name}
                    </p>
                  </section>

                  <section className="mt-4">
                    <h6 className="font-semibold leading-normal">Category</h6>
                    <p className="mt-3 leading-normal text-zinc-500">
                      {companyInfo.category}
                    </p>
                  </section>

                  <section className="mt-4">
                    <h6 className="font-semibold leading-normal">Summary</h6>
                    <p className="mt-3 leading-normal text-zinc-500">
                      {companyInfo.description}
                    </p>
                  </section>

                  {companyInfo.productServices?.length ? (
                    <section className="mt-4">
                      <h6 className="font-semibold leading-normal">
                        Products/Services
                      </h6>

                      <ul className="mt-3 leading-normal text-zinc-500">
                        {companyInfo.productServices.map((product) => (
                          <div key={product.id} className="mt-4">
                            <li className="list-inside list-disc pl-1 text-black">
                              <span className="-ml-2 text-zinc-500">
                                {product.name}
                              </span>
                            </li>

                            {product.features.length ? (
                              <p>
                                <strong className="font-semibold text-black">
                                  Features
                                </strong>{' '}
                                {product.features.join(', ')}
                              </p>
                            ) : null}

                            {product.benefits.length ? (
                              <p>
                                <strong className="font-semibold text-black">
                                  Benefits
                                </strong>{' '}
                                {product.benefits.join(', ')}
                              </p>
                            ) : null}

                            {product.uniqueSellingPoints.length ? (
                              <p>
                                <strong className="font-semibold text-black">
                                  Unique Selling Points
                                </strong>{' '}
                                {product.uniqueSellingPoints.join(', ')}
                              </p>
                            ) : null}
                          </div>
                        ))}
                      </ul>
                    </section>
                  ) : null}
                </div>
              </div>

              <div className="flex gap-4">
                <div className="flex h-12 w-12 min-w-12 items-center justify-center rounded-full border border-zinc-100 bg-white">
                  <img
                    alt="Illustration of a stylized owl with large yellow eyes and gray feathers, perched on a red branch with a serious expression"
                    className="h-10 w-10 rounded-2xl"
                    src="/img/evo.png"
                  />
                </div>

                <div className="rounded-r-lg border bg-white p-8">
                  <button
                    className="text-nowrap rounded-md border border-red-400 bg-red-500 p-4 font-medium leading-none text-white transition-colors hover:border-red-800 hover:bg-red-800"
                    onClick={onCheckoutAIGeneratedQuestions}
                  >
                    Click here to review your AI generated questions
                  </button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </Layout>
  );
}
