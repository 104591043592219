import { render } from '@react-email/components';

import { Rating } from '@/manager-graphql';

function removeDisallowedTagsAndAttributes(embedCode: string): string {
  return embedCode
    .replace(/<!DOCTYPE(.*?)>/g, '')
    .replace(/\s*role="[^"]*"/g, '')
    .replace(/\s*data-id="[^"]*"/g, '');
}

export const getHTMLEmbedCode = async (
  surveyId: string,
  primaryQuestion: string,
  ratings: Rating[]
) => {
  const { default: HTMLEmbedCode } = await import(
    './questions-html-embed-code'
  );
  const embedCode = render(
    <HTMLEmbedCode
      surveyUrl={getSurveyUrl(surveyId)}
      primaryQuestion={primaryQuestion}
      ratings={ratings}
    />
  );
  return removeDisallowedTagsAndAttributes(embedCode);
};

export function getWidgetCode(surveyId: string) {
  const host = window.location.host.includes('dev')
    ? 'storage.googleapis.com/static-dev-theysaid-io-bucket'
    : 'static.theysaid.io';
  return `<script>
  (function (w, d, s, o, f, js, fjs) {
    w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; js.type='module'; fjs.parentNode.insertBefore(js, fjs);
  }(window, document, 'script', '_ts', 'https://${host}/widget/main.js'));
  _ts('init', { surveyId: '${surveyId}' });
</script>`;
}

export const getHTMLiFrameCode = (surveyId: string, isNPS?: boolean) => `<iframe
  src="${getIframeUrl(surveyId)}"
  title="Survey"
  frameborder="0"
  scrolling="no"
  style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:${
    isNPS ? 400 : 320
  }px;width:500px;position:absolute;right:0;bottom:0">
    <p>Your browser does not support iframes.</p>
</iframe>`;

export const getSurveyUrl = (
  surveyId: string,
  baseUrl = window.location.origin
) => `${baseUrl}/survey/${surveyId}`;

export const getIframeUrl = (
  surveyId: string,
  baseUrl = window.location.origin
) => getSurveyUrl(surveyId, baseUrl).replace('/survey/', '/survey/embed/');
