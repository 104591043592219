import {
  Close,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
  type DialogContentProps,
} from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const DialogContent = forwardRef<
  HTMLDivElement,
  Omit<DialogContentProps, 'ref'>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Portal>
      <Overlay className="data-[state=open]:animate-overlay-show fixed inset-0 z-20 bg-black opacity-30" />
      <Content
        {...props}
        className={twMerge(
          'data-[state=open]:animate-dialog-show fixed left-[50%] top-[50%] z-30 max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white shadow-xl focus:outline-none',
          className
        )}
        ref={forwardedRef}
      >
        {children}
      </Content>
    </Portal>
  );
});

export const Dialog = Root;
export const DialogClose = Close;
export const DialogDescription = Description;
export const DialogTitle = Title;
export const DialogTrigger = Trigger;
