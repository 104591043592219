import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { GET_COMPANY_INFO } from '@/manager-graphql';

export function useCompanySummary() {
  const navigate = useNavigate();
  const {
    data: companyInfoData,
    error: companyInfoError,
    loading,
  } = useQuery(GET_COMPANY_INFO);

  const handleQuestionNavigation = () => {
    navigate('/questions');
  };

  return {
    companyInfo: companyInfoData?.companyInfo,
    error: companyInfoError?.message,
    loading: loading,
    onCheckoutAIGeneratedQuestions: handleQuestionNavigation,
  };
}
