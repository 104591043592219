import { faArrowRight, faCalendar } from '@fortawesome/pro-light-svg-icons';
import {
  faBadgeDollar,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import rehypeExtLinks from 'rehype-external-links';
import { twMerge, type ClassNameValue } from 'tailwind-merge';

import { Signal, type GetInsightsQuery } from '@/manager-graphql';
import DeleteInsight from './insight-detail-item-delete';
import InsightDetailItemDrawer from './insight-detail-item-drawer';
import InsightFollowup from './insight-detail-item-followup';
import { formatInsightDetails } from './insight-detail-item.helpers';

interface Props {
  insight: GetInsightsQuery['listInsights']['items'][number] & {
    shouldBlur: boolean;
  };
  onDelete?: (insightId: string) => Promise<void>;
}

export default function InsightDetailItem({ insight, onDelete }: Props) {
  const { name, info, summary, dateTime } = formatInsightDetails(insight);
  const handleBlur = (...classLists: ClassNameValue[]) =>
    twMerge(...classLists, insight.shouldBlur ? 'blur-sm select-none' : '');

  return (
    <li className="divide-y rounded-lg border bg-white shadow-sm">
      <div className="group flex items-center justify-between px-4 py-2.5">
        <div className="flex h-1 items-center justify-between gap-2">
          <div>
            <div className={handleBlur('text-sm font-semibold leading-normal')}>
              {name}
            </div>
            {info ? (
              <div
                className={handleBlur('text-xs leading-tight text-zinc-500')}
              >
                {info}
              </div>
            ) : null}
          </div>

          <div className="flex min-w-20 items-center justify-between gap-1 rounded bg-zinc-50 px-3 py-1">
            <span className="text-xs font-medium leading-tight text-zinc-700">
              Score
            </span>
            <span className="text-lg font-semibold text-zinc-800">
              {insight.primaryAnswer}
            </span>
          </div>

          <div className="inline-flex items-center gap-1 text-gray-500">
            <FontAwesomeIcon icon={faCalendar} />
            <div className="text-xs font-normal leading-none tracking-tight ">
              {dateTime}
            </div>
          </div>

          <div className="hidden group-hover:block">
            <DeleteInsight insightId={insight.id} onDelete={onDelete} />
          </div>
        </div>
        <div className="flex gap-4">
          {insight.signal === Signal.Negative ? (
            <span className="font-m</div>edium my-auto rounded bg-red-50 px-2 py-1 text-xs leading-tight text-red-700">
              <FontAwesomeIcon
                className="mr-1 text-red-700"
                icon={faTriangleExclamation}
              />
              negative signal
            </span>
          ) : insight.signal === Signal.Positive ? (
            <span className="my-auto rounded bg-emerald-50 px-2 py-1 text-xs font-medium leading-tight text-emerald-700">
              <FontAwesomeIcon
                className="mr-1 text-emerald-700"
                icon={faBadgeDollar}
              />
              positive signal
            </span>
          ) : null}

          {typeof insight.followup === 'boolean' ? (
            <InsightFollowup id={insight.id} checked={insight.followup} />
          ) : null}

          <InsightDetailItemDrawer insight={insight}>
            <button
              disabled={insight.shouldBlur || !insight?.hasMessages}
              className={handleBlur(
                'w-52 rounded px-4 py-1.5 text-sm font-medium leading-normal text-zinc-800 enabled:hover:bg-zinc-100 disabled:text-zinc-300'
              )}
            >
              {insight?.hasMessages
                ? 'See full conversation'
                : 'No conversation'}
              <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
            </button>
          </InsightDetailItemDrawer>
        </div>
      </div>

      {summary ? (
        <div
          className={handleBlur(
            'prose max-w-full p-4 text-sm leading-normal text-zinc-600'
          )}
        >
          <Markdown rehypePlugins={[[rehypeExtLinks, { target: '_blank' }]]}>
            {summary}
          </Markdown>
        </div>
      ) : null}
    </li>
  );
}
