import { GetQuestionsQuery } from '@/manager-graphql';
import { Skeleton } from '@/shared-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import { ManagerOnboardingLayout } from './manager-onboarding-layout';
import { useOnboardingQuestions } from './manager-onboarding-questions.hooks';
import { SYSTEM_TAG } from './manger-onboarding.helpers';
import { useOnboarding } from './manager-onboarding.hooks';
import { Navigate } from 'react-router-dom';

// Create the zod schema
const questionsFormSchema = z.object({
  selectedQuestionId: z.string().min(1, 'Select a question'),
});

type FormData = z.infer<typeof questionsFormSchema>;

export default function OnboardingQuestions() {
  const {
    questions,
    isLoading: isQuestionsLoading,
    isSubmitting,
    handleAddSurvey,
    error,
  } = useOnboardingQuestions();
  const { companyInfo, isLoading: isCompanyInfoLoading } = useOnboarding();

  const isLoading = isQuestionsLoading || isCompanyInfoLoading;

  const {
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(questionsFormSchema),
    mode: 'onChange',
  });

  const selectedQuestionId = watch('selectedQuestionId');

  const onSubmit = (data: FormData) => {
    handleAddSurvey({
      questionId: data.selectedQuestionId,
    });
  };

  const renderButtonGroup = (
    options: GetQuestionsQuery['listQuestions']['items'],
    fieldName: keyof FormData
  ) => {
    const fieldValue = watch(fieldName);
    const hasError = !!errors[fieldName]?.message && !fieldValue;

    return (
      <div className="flex flex-col gap-2">
        <div className="inline-flex flex-wrap items-start justify-start gap-3">
          {options.map((option) => (
            <button
              key={option.id}
              type="button"
              onClick={() => {
                setValue(fieldName, option.id, { shouldValidate: true });
                clearErrors(fieldName);
              }}
              className={twMerge(
                classNames(
                  'inline-flex w-full items-center justify-between gap-2 rounded-lg border bg-gray-50 p-3 text-base font-normal leading-normal tracking-tight text-gray-800 transition-all duration-200 ease-in-out',
                  {
                    'border-gray-800': fieldValue === option.id,
                    'border-red-500': hasError,
                    'border-gray-200': !hasError && fieldValue !== option.id,
                  }
                )
              )}
            >
              <span className="mr-2 flex-grow text-left">
                {option.primaryQuestion}
              </span>
              <span className="ml-2 flex-shrink-0 whitespace-nowrap rounded bg-gray-200 px-2 py-1 text-sm font-normal leading-5 text-gray-600">
                {
                  option.tags?.filter((f) => !f.name?.includes(SYSTEM_TAG))?.[0]
                    ?.name
                }
              </span>
            </button>
          ))}
        </div>
        {hasError && (
          <div className="text-sm text-red-600">
            {errors[fieldName]?.message}
          </div>
        )}
      </div>
    );
  };

  if (!isLoading) {
    if (companyInfo?.insightsCount) {
      return <Navigate replace to={'/home'} />;
    }
  }

  if (isLoading) {
    return (
      <ManagerOnboardingLayout>
        <div className="flex flex-col gap-4 p-5">
          {[...Array(5)].map((_, index) => (
            <Skeleton key={index} className="h-14 w-full" />
          ))}
        </div>
      </ManagerOnboardingLayout>
    );
  }

  return (
    <ManagerOnboardingLayout title="Pick a template question below to try out TheySaid's conversational AI survey">
      {error ? (
        <div className="flex flex-col items-center justify-center p-5">
          <p className="text-center text-red-600">
            {error.message || 'An error occurred while loading the questions.'}
          </p>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="relative flex flex-col gap-5 p-5"
        >
          {renderButtonGroup(questions, 'selectedQuestionId')}
          <div className="w-full border-gray-200 pt-5">
            <button
              type="submit"
              className={twMerge(
                'inline-flex h-14 w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2 text-base font-normal leading-normal text-white transition-opacity duration-200 ease-in-out',
                (!selectedQuestionId || isSubmitting) &&
                  'cursor-not-allowed opacity-50'
              )}
              disabled={!selectedQuestionId || isSubmitting}
            >
              {isSubmitting && (
                <div className="mr-2 h-5 w-5 animate-spin rounded-full border-b-2 border-white" />
              )}
              Continue to test it out
            </button>
          </div>
        </form>
      )}
    </ManagerOnboardingLayout>
  );
}
