import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import {
  GET_COMPANY_INFO,
  UPDATE_COMPANY_INFO,
  type UpdateCompanyInfoMutationVariables,
} from '@/manager-graphql';

export function useCompanySummaryEdit() {
  const navigate = useNavigate();
  const { data, error, loading } = useQuery(GET_COMPANY_INFO);
  const [updateCompanyInfo] = useMutation(UPDATE_COMPANY_INFO, {
    refetchQueries: [GET_COMPANY_INFO],
  });

  const handleUpdateCompanyInfo = async (
    input: UpdateCompanyInfoMutationVariables['input']
  ) => {
    Reflect.deleteProperty(input, 'name');
    Reflect.deleteProperty(input, 'website');
    const response = await updateCompanyInfo({ variables: { input } });
    navigate('..');
    return response;
  };

  return {
    companyInfo: data?.companyInfo,
    error: error?.message,
    loading: loading,
    onSubmit: handleUpdateCompanyInfo,
  };
}
