import { faLinkSimple, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { GetQuestionsQuery } from '@/manager-graphql';
import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared-ui';

import { useQuestionsGenerateLinksDrawer } from './questions-generate-links-drawer.hooks';
import QuestionsQRCodeDialog from './questions-qr-code-dialog';
import { QuestionsSurveyPreview } from './questions-survey-preview';

interface Props {
  question: GetQuestionsQuery['listQuestions']['items'][number];
}

export default function QuestionsGenerateLinksDrawer({ question }: Props) {
  const {
    isSubmitting,
    open,
    qrCodeUrl,
    onCloseQrCodeDialog,
    onCopyHTML,
    onCopyIFrame,
    onCopyShareableLink,
    onCopyWidget,
    onOpenChange,
    onGenerateQrCode,
  } = useQuestionsGenerateLinksDrawer({ question });

  const options = [
    {
      title: 'HTML Embed code',
      description:
        'Put a clickable survey into Klaviyo, Mailchimp, Hubspot, Zendesk and more',
      label: 'Copy HTML',
      onClick: onCopyHTML,
    },
    {
      title: 'Widget code',
      description: 'Put an interactive survey on your website',
      label: 'Copy Widget',
      onClick: onCopyWidget,
    },
    {
      title: 'iFrame Embed code',
      description: 'Put a clickable survey onto your website',
      label: 'Copy iFrame',
      onClick: onCopyIFrame,
    },
    {
      title: 'Share a Link',
      description: 'Share a link with your customer via text or email',
      label: 'Get Shareable Link',
      onClick: onCopyShareableLink,
    },
  ];

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerTrigger className="px-10">
        <FontAwesomeIcon
          className="text-emerald-600 transition-colors hover:text-emerald-800"
          icon={faLinkSimple}
        />
      </DrawerTrigger>

      <DrawerContent>
        <div className="flex justify-between overflow-hidden bg-white p-8 pb-4">
          <div className="mb-8 space-y-2">
            <DrawerTitle className="font-display text-2xl font-semibold leading-tight">
              Generate Links
            </DrawerTitle>

            <DrawerDescription className="text-sm leading-normal text-zinc-500">
              Embed an iFrame or HTML code
            </DrawerDescription>
          </div>

          <DrawerClose className="p-4 text-zinc-500 hover:text-zinc-600">
            <FontAwesomeIcon icon={faXmark} />
          </DrawerClose>
        </div>

        <div className="flex flex-grow flex-col space-y-4 overflow-y-auto px-8 pb-6 pt-4">
          <h4 className="font-semibold leading-normal text-zinc-500">
            Instructions:
          </h4>
          <p className="text-sm leading-normal text-zinc-500">
            TheySaid offers multiple ways to share your surveys with your
            customers. A variety of formats are provided by clicking the
            appropriate link below.
          </p>
          <p className="text-sm leading-normal text-zinc-500">
            You may insert the iFrame or HTML code onto your website or into
            your email platform of choice. Shareable Links are also provided for
            SMS or email hyperlinks. QR Codes may be used on signage or at
            events to collect feedback.
          </p>

          <div className="text-sm leading-normal">
            <QuestionsSurveyPreview
              question={question.primaryQuestion}
              ratings={question.ratings}
            />
          </div>

          <div className="divide-y">
            {options.map((item) => (
              <div
                key={item.title}
                className="flex items-center justify-between py-4"
              >
                <div className="max-w-80">
                  <h4 className="font-semibold leading-normal text-zinc-500">
                    {item.title}
                  </h4>
                  <p className="text-sm leading-normal text-zinc-500">
                    {item.description}
                  </p>
                </div>

                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={item.onClick}
                >
                  {item.label}
                </Button>
              </div>
            ))}

            <div className="flex items-center justify-between py-4">
              <div className="max-w-80">
                <h4 className="font-semibold leading-normal text-zinc-500">
                  QR Code
                </h4>
                <p className="text-sm leading-normal text-zinc-500">
                  Share a QR code with your customers on signs or at events
                </p>
              </div>

              <QuestionsQRCodeDialog
                value={qrCodeUrl}
                onClose={onCloseQrCodeDialog}
                onGenerateQrCode={onGenerateQrCode}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end rounded-bl-lg bg-white px-8 py-4">
          <DrawerClose asChild>
            <Button>Done</Button>
          </DrawerClose>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
