import { gql, useMutation } from '@apollo/client';

import { CANCEL_DOWNLOAD } from '@/manager-graphql';

export function useCancelDownload() {
  return useMutation(CANCEL_DOWNLOAD, {
    update(cache, { data }) {
      if (data?.cancelDownload) {
        cache.writeFragment({
          id: `Download:${data.cancelDownload}`,
          fragment: gql`
            fragment CanceledDownload on Download {
              status
            }
          `,
          data: {
            status: 'Canceled',
          },
        });
      }
    },
  });
}
