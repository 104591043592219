import Layout from '@/manager-sidebar';
import {
  PageHeader,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';

import QuestionsAccordion from './questions-accordion';
import AddCustomQuestionForm from './questions-add-custom-question-form';
import { useQuestions } from './questions.hooks';

export default function Questions() {
  const {
    aiQuestions,
    systemQuestions,
    userQuestions,
    error,
    loading,
    onAddCustomQuestion,
    onUpdateQuestion,
    onLoadMoreAiQuestions,
    onLoadMoreSystemQuestions,
    onLoadMoreUserQuestions,
  } = useQuestions();

  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Questions" />

        <main className="px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error?.message} />
          ) : (
            <div className="flex flex-col gap-6">
              {aiQuestions.length > 0 && (
                <QuestionsAccordion
                  groupName="Recommended Questions - Generated by Theysaid AI based on your business"
                  questions={aiQuestions}
                  onUpdateQuestion={onUpdateQuestion}
                  onLoadMore={onLoadMoreAiQuestions}
                />
              )}

              {systemQuestions.length > 0 && (
                <QuestionsAccordion
                  groupName="Industry Question Bank"
                  questions={systemQuestions}
                  onUpdateQuestion={onUpdateQuestion}
                  onLoadMore={onLoadMoreSystemQuestions}
                />
              )}

              {userQuestions.length > 0 && (
                <QuestionsAccordion
                  groupName="My Custom Questions"
                  questions={userQuestions}
                  onUpdateQuestion={onUpdateQuestion}
                  onLoadMore={onLoadMoreUserQuestions}
                />
              )}
            </div>
          )}
        </main>

        <AddCustomQuestionForm onSubmit={onAddCustomQuestion} />
      </div>
    </Layout>
  );
}
