import { type GetInsightsQuery } from '@/manager-graphql';
import { faker } from '@faker-js/faker';

export function formatDateTime(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
}

export function formatInfo(
  contact: GetInsightsQuery['listInsights']['items'][number]['contact']
): string {
  return [contact?.email, contact?.phone].filter(Boolean).join(' - ');
}

export function formatName(
  contact: GetInsightsQuery['listInsights']['items'][number]['contact']
): string {
  return (
    [contact?.firstName, contact?.lastName].filter(Boolean).join(' ') ||
    'Anonymous'
  );
}

export function formatInsightDetails(
  insight: GetInsightsQuery['listInsights']['items'][number] & {
    shouldBlur: boolean;
  }
): { name: string; info: string; summary: string; dateTime: string } {
  const name = insight.shouldBlur
    ? faker.person.fullName()
    : formatName(insight.contact);
  const info = insight.shouldBlur
    ? faker.internet.email({
        firstName: name.split(' ')[0].toLowerCase(),
        lastName: name.split(' ')[1].toLowerCase(),
      })
    : formatInfo(insight.contact);
  const summary =
    insight.shouldBlur && insight.summary
      ? faker.lorem.paragraph(5)
      : insight.summary ?? '';
  return {
    dateTime: formatDateTime(insight.createdAt),
    info,
    name,
    summary,
  };
}
