import { useMutation, useQuery } from '@apollo/client';

import {
  ADD_CUSTOM_QUESTION,
  GET_QUESTIONS,
  GetQuestionsDocument,
  OrderDirection,
  QuestionOrderField,
  UPDATE_QUESTION,
  type AddCustomQuestionMutationVariables,
  type UpdateQuestionMutationVariables,
} from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useCallback } from 'react';

export function useCopyToClipboard() {
  return useCallback(async (blob: Blob) => {
    if (typeof ClipboardItem && navigator?.clipboard?.write) {
      return navigator.clipboard.write([
        new ClipboardItem({ [blob.type]: blob }),
      ]);
    } else {
      throw new Error('Copy to clipboard is not supported in this browser');
    }
  }, []);
}

const orderBy = {
  direction: OrderDirection.Desc,
  field: QuestionOrderField.CreatedAt,
};
const aiFilter = { tags: ['ai'] };
const systemFilter = { tags: ['system'] };
const userFilter = { tags: ['user'] };

export function useQuestions() {
  const { addToast } = useToast();
  const {
    data: aiData,
    error: aiError,
    fetchMore: fetchMoreAi,
    loading: isLoadingAi,
  } = useQuery(GET_QUESTIONS, {
    variables: { filter: aiFilter, orderBy },
  });
  const {
    data: systemData,
    error: systemError,
    fetchMore: fetchMoreSystem,
    loading: isLoadingSystem,
  } = useQuery(GET_QUESTIONS, {
    variables: { filter: systemFilter, orderBy },
  });
  const {
    data: userData,
    error: userError,
    fetchMore: fetchMoreUser,
    loading: isLoadingUser,
  } = useQuery(GET_QUESTIONS, {
    variables: { filter: userFilter, orderBy },
  });
  const [addCustomQuestion] = useMutation(ADD_CUSTOM_QUESTION, {
    refetchQueries: [GetQuestionsDocument],
  });
  const [updateQuestion] = useMutation(UPDATE_QUESTION, {
    refetchQueries: [GetQuestionsDocument],
  });

  const onLoadMoreAiQuestions = aiData?.listQuestions.pageInfo.hasNextPage
    ? () => {
        fetchMoreAi({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              listQuestions: {
                ...fetchMoreResult.listQuestions,
                items: [
                  ...prev.listQuestions.items,
                  ...fetchMoreResult.listQuestions.items,
                ],
              },
            };
          },
          variables: {
            after: aiData.listQuestions.pageInfo.endCursor,
          },
        });
      }
    : undefined;

  const onLoadMoreSystemQuestions = systemData?.listQuestions.pageInfo
    .hasNextPage
    ? () => {
        fetchMoreSystem({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              listQuestions: {
                ...fetchMoreResult.listQuestions,
                items: [
                  ...prev.listQuestions.items,
                  ...fetchMoreResult.listQuestions.items,
                ],
              },
            };
          },
          variables: {
            after: systemData.listQuestions.pageInfo.endCursor,
          },
        });
      }
    : undefined;

  const onLoadMoreUserQuestions = userData?.listQuestions.pageInfo.hasNextPage
    ? () => {
        fetchMoreUser({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              listQuestions: {
                ...fetchMoreResult.listQuestions,
                items: [
                  ...prev.listQuestions.items,
                  ...fetchMoreResult.listQuestions.items,
                ],
              },
            };
          },
          variables: {
            after: userData.listQuestions.pageInfo.endCursor,
          },
        });
      }
    : undefined;

  const onAddCustomQuestion = async (
    variables: AddCustomQuestionMutationVariables
  ) => {
    return addCustomQuestion({
      onError: ({ message }) => {
        addToast({ title: message, type: 'error' });
      },
      variables,
    });
  };

  const onUpdateQuestion = async (
    input: UpdateQuestionMutationVariables['input']
  ) => {
    return updateQuestion({
      onError: ({ message }) => {
        addToast({ title: message, type: 'error' });
      },
      variables: { input },
    });
  };

  return {
    aiQuestions: aiData?.listQuestions.items ?? [],
    systemQuestions: systemData?.listQuestions.items ?? [],
    userQuestions: userData?.listQuestions.items ?? [],
    error: aiError || systemError || userError,
    loading: isLoadingAi || isLoadingSystem || isLoadingUser,
    onAddCustomQuestion,
    onUpdateQuestion,
    onLoadMoreAiQuestions,
    onLoadMoreSystemQuestions,
    onLoadMoreUserQuestions,
  };
}
