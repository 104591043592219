import {
  faChevronLeft,
  faCircleInfo,
  faSpinner,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import Layout from '@/manager-sidebar';
import {
  Gauge,
  PageHeader,
  StateMessageError,
  StateMessageLoading,
  Summary,
  Tooltip,
} from '@/shared-ui';
import InsightDetailItem from './insight-detail-item';
import { useInsightDetail } from './insight-detail.hooks';

interface Props {
  questionId: string;
}

export default function InsightDetail({ questionId }: Props) {
  const {
    error,
    insights,
    isLoading,
    isLoadingSummary,
    question,
    summary,
    onDeleteInsight,
    onGenerateSummary,
    onLoadMoreInsights,
  } = useInsightDetail(questionId);

  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Insights" className="z-10">
          {!isLoading && question ? (
            <div className="flex flex-col gap-4 bg-white px-10 py-8">
              <div>
                <Link
                  to=".."
                  relative="path"
                  className="flex items-center text-sm leading-normal text-zinc-700"
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="mr-1 text-xs"
                  />
                  Back
                </Link>
              </div>

              <div>
                <h2 className="font-display text-2xl font-semibold leading-tight">
                  {question.primaryQuestion}
                </h2>
              </div>

              <div className="flex flex-row gap-4">
                {question.stats.map((item) => (
                  <div
                    key={item.name}
                    className="flex basis-1/5 justify-between rounded-lg border border-zinc-200 bg-white p-4 shadow-sm hover:shadow"
                  >
                    <div className="flex flex-col justify-between">
                      <span className="text-xs font-medium leading-tight text-zinc-500">
                        {item.name}{' '}
                        {item.tooltip && (
                          <Tooltip content={item.tooltip}>
                            <FontAwesomeIcon
                              className="text-zinc-500 transition-colors hover:text-emerald-600"
                              icon={faCircleInfo}
                            />
                          </Tooltip>
                        )}
                      </span>
                      <span className="font-display text-2xl font-semibold leading-tight text-zinc-800">
                        {item.value}
                      </span>
                    </div>
                    {item?.showGauge && <Gauge value={+(item?.value || '0')} />}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </PageHeader>

        <main className="px-10 py-6">
          {isLoading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error} />
          ) : (
            <>
              {insights.length ? (
                <Summary
                  loading={isLoadingSummary}
                  summary={summary}
                  onGenerate={onGenerateSummary}
                />
              ) : null}

              <ul className="mt-6 flex flex-col gap-2">
                {insights.map((insight) => (
                  <InsightDetailItem
                    key={insight.id}
                    insight={insight}
                    onDelete={onDeleteInsight}
                  />
                ))}
                {onLoadMoreInsights ? (
                  <InView
                    as="div"
                    onChange={onLoadMoreInsights}
                    className="mx-auto mt-4"
                  >
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin"
                    />
                  </InView>
                ) : null}
              </ul>
            </>
          )}
        </main>
      </div>
    </Layout>
  );
}
