import {
  useMutation,
  useQuery,
  type QueryHookOptions,
  type Reference,
} from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DELETE_INSIGHT,
  GENERATE_QUESTION_SUMMARY,
  GET_INSIGHTS,
  GET_MESSAGES,
  GET_QUESTION_SUMMARY,
  GET_QUESTION_WITH_STATS,
  GetInsightsDocument,
  GetQuestionWithStatsDocument,
  UPDATE_INSIGHTS,
  type GetMessagesQuery,
  type GetMessagesQueryVariables,
} from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useUpgrade } from '@/manager-upgrade';
import { isNPSQuestion } from './insight-list-item.helpers';

export const useGetMessagesQuery = (
  options: QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables> = {}
) => useQuery(GET_MESSAGES, options);

export function useInsightDetail(questionId: string) {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { planDetails } = useUpgrade();
  const totalAllowedInsights = planDetails?.totalAllowedInsights ?? 10;
  const since = new Date();
  since.setMonth(since.getMonth() - 12);
  since.setHours(0, 0, 0, 0);
  const until = new Date();
  until.setHours(23, 59, 59, 999);

  const {
    data: questionData,
    error: questionError,
    loading: isLoadingQuestion,
  } = useQuery(GET_QUESTION_WITH_STATS, { variables: { id: questionId } });
  const {
    data: insightsData,
    error: insightsError,
    loading: isLoadingInsights,
    fetchMore,
  } = useQuery(GET_INSIGHTS, { variables: { filter: { questionId } } });
  const { data: summaryData, loading: isLoadingSummary } = useQuery(
    GET_QUESTION_SUMMARY,
    { variables: { filter: { id: questionId, since, until } } }
  );

  const [deleteInsight] = useMutation(DELETE_INSIGHT);
  const [updateInsights] = useMutation(UPDATE_INSIGHTS);
  const [generateSummary, { loading: isGeneratingSummary }] = useMutation(
    GENERATE_QUESTION_SUMMARY
  );

  const handleGenerateSummary = async () => {
    await generateSummary({
      update: (cache, { data }) => {
        if (data?.generateQuestionSummary) {
          cache.writeQuery({
            query: GET_QUESTION_SUMMARY,
            variables: { filter: { id: questionId, since, until } },
            data: { questionSummary: data.generateQuestionSummary },
          });
        } else if (data?.generateQuestionSummary === null) {
          addToast({ title: 'No summary yet', type: 'info' });
        }
      },
      variables: {
        input: {
          id: questionId,
          since,
          until,
        },
      },
    });
  };

  const handleLoadMore = () => {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          listInsights: {
            ...fetchMoreResult.listInsights,
            items: [
              ...prev.listInsights.items,
              ...fetchMoreResult.listInsights.items,
            ],
          },
        };
      },
      variables: {
        filter: { questionId },
        after: insightsData?.listInsights.pageInfo.endCursor,
      },
    });
  };

  const handleMarkAsRead = useCallback(
    async (insightIds: string[]) => {
      await updateInsights({
        refetchQueries: [GetQuestionWithStatsDocument],
        variables: {
          input: {
            filter: { ids: insightIds },
            values: { msgRead: true },
          },
        },
      });
    },
    [updateInsights]
  );

  const handleDeleteInsight = useCallback(
    async (insightId: string) => {
      await deleteInsight({
        refetchQueries: [GetQuestionWithStatsDocument, GetInsightsDocument],
        update(cache, _, { variables }) {
          cache.modify({
            fields: {
              insights(existingInsightsRefs = [], { readField }) {
                return existingInsightsRefs.filter(
                  (insightRef: Reference) =>
                    variables?.id !== readField('id', insightRef)
                );
              },
            },
          });
        },
        variables: {
          id: insightId,
        },
      });
    },
    [deleteInsight]
  );

  const question = useMemo(() => {
    if (!questionData?.question) return undefined;
    return {
      ...questionData.question,
      stats: [
        {
          name: 'Insights',
          value: questionData.question.totalInsights,
          tooltip: 'Total number of survey responses',
        },
        isNPSQuestion(questionData.question.tags)
          ? {
              name: 'NPS Score',
              value: questionData.question.avgScore?.toFixed(0),
              tooltip: 'NPS score provided by survey responders',
              showGauge: true,
            }
          : {
              name: 'Avg Score',
              value: questionData.question.avgScore?.toFixed(1),
              tooltip: 'Average  numeric score provided by survey responders',
            },
        {
          name: 'Negative signals',
          value: questionData.question.totalRiskSignals,
        },
        {
          name: 'Positive signals',
          value: questionData.question.totalGrowthSignals,
        },
        {
          name: 'Conversation Depth',
          value: questionData.question.avgMessageDepth?.toFixed(1),
          tooltip:
            'Median number of back and forth interactions including the initial response.',
        },
      ],
    };
  }, [questionData]);

  const insights = useMemo(() => {
    if (!insightsData) return [];
    const allowedIndex =
      (insightsData.listInsights.pageInfo.total ?? 0) - totalAllowedInsights;
    return insightsData.listInsights.items.map((insight, index) => ({
      ...insight,
      shouldBlur: index < allowedIndex,
    }));
  }, [insightsData, totalAllowedInsights]);

  useEffect(() => {
    if (insights.length) {
      const unreadIds = insights.reduce((acc, insight) => {
        if (!insight.msgRead) {
          acc.push(insight.id);
        }
        return acc;
      }, [] as string[]);
      if (unreadIds.length) {
        handleMarkAsRead(unreadIds);
      }
    }
  }, [insights, handleMarkAsRead]);

  useEffect(() => {
    if (
      !isLoadingQuestion &&
      !isLoadingInsights &&
      (!questionData || questionData?.question.totalInsights === 0)
    ) {
      navigate('..');
    }
  }, [isLoadingInsights, isLoadingQuestion, navigate, questionData]);

  return {
    error: insightsError?.message || questionError?.message,
    insights: insights,
    isLoading: isLoadingInsights || isLoadingQuestion,
    isLoadingSummary: isLoadingSummary || isGeneratingSummary,
    question,
    summary: summaryData?.questionSummary,
    onDeleteInsight: handleDeleteInsight,
    onGenerateSummary: handleGenerateSummary,
    onLoadMoreInsights: insightsData?.listInsights.pageInfo.hasNextPage
      ? handleLoadMore
      : undefined,
  };
}
