import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import { DashboardEmptyState } from '@/manager-home';
import Layout from '@/manager-sidebar';
import { useUpgrade } from '@/manager-upgrade';
import {
  PageHeader,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';
import InsightListDownloads from './insight-list-downloads';
import InsightListItem from './insight-list-item';
import { useInsightList } from './insight-list.hooks';

export default function InsightList() {
  const { questions, error, isLoading, onLoadMore } = useInsightList();

  const {
    remainingInsights,
    // handleToggleUpgrade,
    // loading: upgradeDataLoading,
  } = useUpgrade();
  const [showUpgradeWarning, setShowUpgradeWarning] = useState(false);
  // const displayUpgradeWarning: boolean =
  //   !loading &&
  //   !error &&
  //   !!questions?.length &&
  //   showUpgradeWarning &&
  //   !upgradeDataLoading;

  useEffect(() => {
    setShowUpgradeWarning(() => remainingInsights <= 10.0);
  }, [remainingInsights]);

  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Insights" headerRight={<InsightListDownloads />} />

        {/* {displayUpgradeWarning ? (
          <div className="flex w-full items-center justify-between gap-2 bg-amber-100 p-3">
            <div className="flex w-full items-center gap-2 bg-amber-100">
              <div className="font-display flex h-8 w-8 items-center justify-center rounded-full bg-amber-400 text-xl uppercase text-black">
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </div>
              <span className="text-left text-base font-extralight">
                You have{' '}
                <span className="font-bold">
                  {remainingInsights} insights remaining
                </span>{' '}
                this month. Upgrade now to continue enjoying our services
                without interruptions!
              </span>
            </div>
            <div className="flex">
              <Button color="text" onClick={handleToggleUpgrade}>
                Upgrade Now
              </Button>
              <Button color="text" onClick={() => setShowUpgradeWarning(false)}>
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </div>
          </div>
        ) : null} */}

        <main className="px-10 py-6">
          {isLoading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error} />
          ) : !questions?.length ? (
            <DashboardEmptyState />
          ) : (
            <>
              <ul className="grid auto-rows-max grid-cols-[repeat(auto-fill,_minmax(24rem,_3fr))] gap-6">
                {questions.map((props) => (
                  <li key={props.id}>
                    <Link to={props.id}>
                      <InsightListItem {...props} />
                    </Link>
                  </li>
                ))}
              </ul>

              {onLoadMore ? (
                <InView
                  as="div"
                  onChange={onLoadMore}
                  className="mt-4 text-center"
                >
                  <FontAwesomeIcon icon={faSpinner} spin />
                </InView>
              ) : null}
            </>
          )}
        </main>
      </div>
    </Layout>
  );
}
