import { Navigate } from 'react-router-dom';

import Layout from '@/manager-sidebar';
import {
  PageHeader,
  PageHeaderNav,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';
import { useCompanySummaryEdit } from './company-summary-edit.hooks';
import CompanySummaryForm from './company-summary-form';

export default function CompanySummaryEdit() {
  const { companyInfo, error, loading, onSubmit } = useCompanySummaryEdit();

  if (error === 'Organization not found') {
    return <Navigate replace to={'..'} />;
  }

  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Evo AI Company Summary">
          <PageHeaderNav
            items={[
              { name: 'Dashboard', to: '/home/dashboard' },
              { name: 'Company Summary', to: '/home/company-summary' },
            ]}
          />
        </PageHeader>

        <main className="px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error || !companyInfo ? (
            <StateMessageError
              message={error || 'Failed to load company info'}
            />
          ) : (
            <CompanySummaryForm companyInfo={companyInfo} onSubmit={onSubmit} />
          )}
        </main>
      </div>
    </Layout>
  );
}
