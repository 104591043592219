import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from '@/shared-ui';
import Layout from '@/manager-sidebar';
import React, { memo } from 'react';

interface ManagerOnboardingLayoutProps {
  children: React.ReactNode;
  title?: string;
}

const ManagerOnboardingLayout = memo(function ManagerOnboardingLayout({
  children,
  title,
}: ManagerOnboardingLayoutProps) {
  return (
    <>
      <Layout>
        <span />
      </Layout>
      <Dialog defaultOpen>
        <DialogContent
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => e.preventDefault()}
          className="flex max-h-[90vh] w-[90vw] max-w-[700px] flex-col overflow-hidden"
        >
          <DialogTitle className="flex-shrink-0 pt-3">
            <div className="flex flex-col gap-3 border-gray-200 px-6 pb-4 pt-6">
              <img
                alt="TheySaid logo"
                className="mx-auto h-[36.17px]"
                src="/img/theysaid-logo.png"
              />
              <span className="pb-3 text-center text-xs font-normal text-gray-500">
                The World's First Conversational AI Survey
              </span>

              <div className="inline-flex items-start justify-start gap-4 rounded-xl bg-gray-50 p-4">
                <img
                  src="/img/evo.png"
                  alt="Illustration of a stylized owl with large yellow eyes and gray feathers, perched on a red branch with a serious expression"
                  className="h-10 w-10 rounded-lg bg-white shadow-lg"
                />
                <span className="text-md w-full text-gray-500">{title}</span>
              </div>
            </div>
          </DialogTitle>
          <DialogDescription />
          <div className="flex-grow overflow-y-auto overscroll-contain">
            {children}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

export { ManagerOnboardingLayout };
