/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation AddApikey($input: ApikeyAddInput!) {\n    addKey(input: $input) {\n      id\n      name\n      createdAt\n    }\n  }\n": types.AddApikeyDocument,
    "\n  mutation AddCompany($input: OrganizationCreateInput!) {\n    addCompany(input: $input) {\n      id\n      name\n      category\n      description\n      website\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n    }\n  }\n": types.AddCompanyDocument,
    "\n  mutation AddCustomQuestion($question: String!) {\n    addCustomQuestion(question: $question) {\n      id\n      primaryQuestion\n      ratings {\n        id\n        label\n        value\n      }\n      tags {\n        id\n        name\n      }\n    }\n  }\n": types.AddCustomQuestionDocument,
    "\n  mutation AddSurvey($input: SurveyCreateInput!) {\n    addSurvey(input: $input) {\n      expiresAt\n      id\n    }\n  }\n": types.AddSurveyDocument,
    "\n  mutation CancelDownload($id: UUID!) {\n    cancelDownload(id: $id)\n  }\n": types.CancelDownloadDocument,
    "\n  subscription CompanyAdded {\n    companyAdded {\n      id\n    }\n  }\n": types.CompanyAddedDocument,
    "\n  mutation CreateCustomerPortalSession {\n    createCustomerPortalSession {\n      url\n    }\n  }\n": types.CreateCustomerPortalSessionDocument,
    "\n  mutation DeleteApikey($id: ID!) {\n    deleteKey(id: $id) {\n      id\n    }\n  }\n": types.DeleteApikeyDocument,
    "\n  mutation DeleteInsight($id: UUID!) {\n    deleteInsight(id: $id) {\n      id\n    }\n  }\n": types.DeleteInsightDocument,
    "\n  mutation DeleteUser($id: ID!) {\n    deleteUser(id: $id)\n  }\n": types.DeleteUserDocument,
    "\n  mutation ExportInsights {\n    exportInsights {\n      id\n      createdAt\n      expiresAt\n      status\n      size\n      url\n    }\n  }\n": types.ExportInsightsDocument,
    "\n  mutation GenerateDashboardSummary($input: DashboardFilterInput!) {\n    generateDashboardSummary(input: $input)\n  }\n": types.GenerateDashboardSummaryDocument,
    "\n  mutation GenerateQuestionSummary($input: QuestionSummaryInput!) {\n    generateQuestionSummary(input: $input)\n  }\n": types.GenerateQuestionSummaryDocument,
    "\n  query GetApiDocs {\n    apiDoc {\n      name\n      pages {\n        content\n        id\n        name\n      }\n    }\n  }\n": types.GetApiDocsDocument,
    "\n  query GetApikeys {\n    listKeys {\n      id\n      name\n      createdAt\n    }\n  }\n": types.GetApikeysDocument,
    "\n  query GetCompanyInfo($insightsCountInput: DashboardFilterInput) {\n    companyInfo {\n      id\n      name\n      category\n      description\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n      website\n      insightsCount(filter: $insightsCountInput)\n    }\n  }\n": types.GetCompanyInfoDocument,
    "\n  query GetDashboardSummary($filter: DashboardFilterInput!) {\n    dashboardSummary(filter: $filter) {\n      summary\n    }\n  }\n": types.GetDashboardSummaryDocument,
    "\n  query GetDashboard($filter: DashboardFilterInput!) {\n    dashboard(filter: $filter) {\n      totalGrowthSignals\n      totalInsights\n      totalRiskSignals\n      avgMessageDepth\n      avgScore\n      npsScore\n      ratingDistribution {\n        percentage\n        score\n      }\n      summary\n    }\n  }\n": types.GetDashboardDocument,
    "\n  query GetDownloads {\n    downloads {\n      id\n      createdAt\n      expiresAt\n      status\n      size\n      url\n    }\n  }\n": types.GetDownloadsDocument,
    "\n  query GetInsights($after: Cursor, $filter: InsightListFilterInput!) {\n    listInsights(after: $after, filter: $filter) {\n      items {\n        id\n        contact {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n        createdAt\n        followup\n        msgRead\n        primaryAnswer\n        signal\n        summary\n        hasMessages\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n        total\n      }\n    }\n  }\n": types.GetInsightsDocument,
    "\n  query GetInsightsLightWeight(\n    $after: Cursor\n    $filter: InsightListFilterInput!\n  ) {\n    listInsights(after: $after, filter: $filter) {\n      items {\n        id\n        createdAt\n      }\n    }\n  }\n": types.GetInsightsLightWeightDocument,
    "\n  query GetMessages($after: Cursor, $filter: MessageListFilterInput!) {\n    listMessages(after: $after, filter: $filter) {\n      items {\n        createdAt\n        id\n        sender\n        text\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetMessagesDocument,
    "\n  query GetOrganizationStatus {\n    companyInfo {\n      id\n      website\n    }\n  }\n": types.GetOrganizationStatusDocument,
    "\n  query GetQuestionSummary($filter: QuestionSummaryInput!) {\n    questionSummary(filter: $filter)\n  }\n": types.GetQuestionSummaryDocument,
    "\n  query GetQuestionWithStats($id: UUID!) {\n    question(id: $id) {\n      id\n      primaryQuestion\n      avgScore\n      avgMessageDepth\n      totalInsights\n      totalGrowthSignals\n      totalRiskSignals\n      totalUnreadInsights\n      tags {\n        id\n        name\n      }\n    }\n  }\n": types.GetQuestionWithStatsDocument,
    "\n  query GetQuestionsWithStats($after: Cursor, $orderBy: QuestionOrder) {\n    questions(after: $after, orderBy: $orderBy) {\n      items {\n        id\n        primaryQuestion\n        avgScore\n        avgMessageDepth\n        totalInsights\n        totalGrowthSignals\n        totalRiskSignals\n        totalUnreadInsights\n        tags {\n          id\n          name\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetQuestionsWithStatsDocument,
    "\n  query GetQuestions(\n    $after: Cursor\n    $filter: QuestionListFilterInput\n    $first: Int\n    $orderBy: QuestionOrder\n  ) {\n    listQuestions(\n      after: $after\n      first: $first\n      filter: $filter\n      orderBy: $orderBy\n    ) {\n      items {\n        id\n        primaryQuestion\n        tags {\n          id\n          name\n        }\n        ratings {\n          id\n          label\n          value\n        }\n        totalInsights\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetQuestionsDocument,
    "\n  query GetUnreadInsights {\n    questions {\n      items {\n        id\n        totalUnreadInsights\n      }\n    }\n  }\n": types.GetUnreadInsightsDocument,
    "\n  query GetUpgradeData($insightsCountInput: DashboardFilterInput) {\n    companyInfo {\n      id\n      stripeCustomerId\n      insightsCount(filter: $insightsCountInput)\n      planDetails {\n        planCode\n        totalAllowedInsights\n      }\n    }\n  }\n": types.GetUpgradeDataDocument,
    "\n  query GetUserManagementData(\n    $after: Cursor\n    $before: Cursor\n    $first: Int\n    $last: Int\n  ) {\n    invitations(after: $after, before: $before, first: $first, last: $last) {\n      items {\n        id\n        email\n      }\n    }\n    users(after: $after, before: $before, first: $first, last: $last) {\n      items {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n  }\n": types.GetUserManagementDataDocument,
    "\n  mutation InviteUser($input: InviteInput!) {\n    inviteUser(input: $input) {\n      id\n      email\n    }\n  }\n": types.InviteUserDocument,
    "\n  mutation RefreshCompanyInfo {\n    refreshCompanyInfo {\n      id\n      name\n      category\n      description\n      website\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n    }\n  }\n": types.RefreshCompanyInfoDocument,
    "\n  mutation ResendInvitation($id: ID!) {\n    resendInvitation(id: $id) {\n      id\n      email\n    }\n  }\n": types.ResendInvitationDocument,
    "\n  mutation RevokeInvitation($id: ID!) {\n    revokeInvitation(id: $id)\n  }\n": types.RevokeInvitationDocument,
    "\n  mutation UpdateCompanyInfo($input: OrganizationUpdateInput!) {\n    updateCompanyInfo(input: $input) {\n      id\n      category\n      description\n      name\n      productServices {\n        benefits\n        features\n        id\n        name\n        uniqueSellingPoints\n      }\n      website\n    }\n  }\n": types.UpdateCompanyInfoDocument,
    "\n  mutation UpdateInsight($input: UpdateInsightInput!) {\n    updateInsight(input: $input) {\n      id\n      followup\n    }\n  }\n": types.UpdateInsightDocument,
    "\n  mutation UpdateInsights($input: UpdateInsightsInput!) {\n    updateInsights(input: $input)\n  }\n": types.UpdateInsightsDocument,
    "\n  mutation UpdateQuestion($input: QuestionUpdateInput!) {\n    updateQuestion(input: $input) {\n      id\n      primaryQuestion\n      ratings {\n        id\n        label\n        value\n      }\n    }\n  }\n": types.UpdateQuestionDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddApikey($input: ApikeyAddInput!) {\n    addKey(input: $input) {\n      id\n      name\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  mutation AddApikey($input: ApikeyAddInput!) {\n    addKey(input: $input) {\n      id\n      name\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddCompany($input: OrganizationCreateInput!) {\n    addCompany(input: $input) {\n      id\n      name\n      category\n      description\n      website\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddCompany($input: OrganizationCreateInput!) {\n    addCompany(input: $input) {\n      id\n      name\n      category\n      description\n      website\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddCustomQuestion($question: String!) {\n    addCustomQuestion(question: $question) {\n      id\n      primaryQuestion\n      ratings {\n        id\n        label\n        value\n      }\n      tags {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddCustomQuestion($question: String!) {\n    addCustomQuestion(question: $question) {\n      id\n      primaryQuestion\n      ratings {\n        id\n        label\n        value\n      }\n      tags {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddSurvey($input: SurveyCreateInput!) {\n    addSurvey(input: $input) {\n      expiresAt\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AddSurvey($input: SurveyCreateInput!) {\n    addSurvey(input: $input) {\n      expiresAt\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CancelDownload($id: UUID!) {\n    cancelDownload(id: $id)\n  }\n"): (typeof documents)["\n  mutation CancelDownload($id: UUID!) {\n    cancelDownload(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription CompanyAdded {\n    companyAdded {\n      id\n    }\n  }\n"): (typeof documents)["\n  subscription CompanyAdded {\n    companyAdded {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateCustomerPortalSession {\n    createCustomerPortalSession {\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCustomerPortalSession {\n    createCustomerPortalSession {\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteApikey($id: ID!) {\n    deleteKey(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteApikey($id: ID!) {\n    deleteKey(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteInsight($id: UUID!) {\n    deleteInsight(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteInsight($id: UUID!) {\n    deleteInsight(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUser($id: ID!) {\n    deleteUser(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteUser($id: ID!) {\n    deleteUser(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ExportInsights {\n    exportInsights {\n      id\n      createdAt\n      expiresAt\n      status\n      size\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation ExportInsights {\n    exportInsights {\n      id\n      createdAt\n      expiresAt\n      status\n      size\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateDashboardSummary($input: DashboardFilterInput!) {\n    generateDashboardSummary(input: $input)\n  }\n"): (typeof documents)["\n  mutation GenerateDashboardSummary($input: DashboardFilterInput!) {\n    generateDashboardSummary(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateQuestionSummary($input: QuestionSummaryInput!) {\n    generateQuestionSummary(input: $input)\n  }\n"): (typeof documents)["\n  mutation GenerateQuestionSummary($input: QuestionSummaryInput!) {\n    generateQuestionSummary(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetApiDocs {\n    apiDoc {\n      name\n      pages {\n        content\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetApiDocs {\n    apiDoc {\n      name\n      pages {\n        content\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetApikeys {\n    listKeys {\n      id\n      name\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetApikeys {\n    listKeys {\n      id\n      name\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCompanyInfo($insightsCountInput: DashboardFilterInput) {\n    companyInfo {\n      id\n      name\n      category\n      description\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n      website\n      insightsCount(filter: $insightsCountInput)\n    }\n  }\n"): (typeof documents)["\n  query GetCompanyInfo($insightsCountInput: DashboardFilterInput) {\n    companyInfo {\n      id\n      name\n      category\n      description\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n      website\n      insightsCount(filter: $insightsCountInput)\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDashboardSummary($filter: DashboardFilterInput!) {\n    dashboardSummary(filter: $filter) {\n      summary\n    }\n  }\n"): (typeof documents)["\n  query GetDashboardSummary($filter: DashboardFilterInput!) {\n    dashboardSummary(filter: $filter) {\n      summary\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDashboard($filter: DashboardFilterInput!) {\n    dashboard(filter: $filter) {\n      totalGrowthSignals\n      totalInsights\n      totalRiskSignals\n      avgMessageDepth\n      avgScore\n      npsScore\n      ratingDistribution {\n        percentage\n        score\n      }\n      summary\n    }\n  }\n"): (typeof documents)["\n  query GetDashboard($filter: DashboardFilterInput!) {\n    dashboard(filter: $filter) {\n      totalGrowthSignals\n      totalInsights\n      totalRiskSignals\n      avgMessageDepth\n      avgScore\n      npsScore\n      ratingDistribution {\n        percentage\n        score\n      }\n      summary\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDownloads {\n    downloads {\n      id\n      createdAt\n      expiresAt\n      status\n      size\n      url\n    }\n  }\n"): (typeof documents)["\n  query GetDownloads {\n    downloads {\n      id\n      createdAt\n      expiresAt\n      status\n      size\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInsights($after: Cursor, $filter: InsightListFilterInput!) {\n    listInsights(after: $after, filter: $filter) {\n      items {\n        id\n        contact {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n        createdAt\n        followup\n        msgRead\n        primaryAnswer\n        signal\n        summary\n        hasMessages\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n        total\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetInsights($after: Cursor, $filter: InsightListFilterInput!) {\n    listInsights(after: $after, filter: $filter) {\n      items {\n        id\n        contact {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n        createdAt\n        followup\n        msgRead\n        primaryAnswer\n        signal\n        summary\n        hasMessages\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n        total\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInsightsLightWeight(\n    $after: Cursor\n    $filter: InsightListFilterInput!\n  ) {\n    listInsights(after: $after, filter: $filter) {\n      items {\n        id\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetInsightsLightWeight(\n    $after: Cursor\n    $filter: InsightListFilterInput!\n  ) {\n    listInsights(after: $after, filter: $filter) {\n      items {\n        id\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMessages($after: Cursor, $filter: MessageListFilterInput!) {\n    listMessages(after: $after, filter: $filter) {\n      items {\n        createdAt\n        id\n        sender\n        text\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMessages($after: Cursor, $filter: MessageListFilterInput!) {\n    listMessages(after: $after, filter: $filter) {\n      items {\n        createdAt\n        id\n        sender\n        text\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetOrganizationStatus {\n    companyInfo {\n      id\n      website\n    }\n  }\n"): (typeof documents)["\n  query GetOrganizationStatus {\n    companyInfo {\n      id\n      website\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuestionSummary($filter: QuestionSummaryInput!) {\n    questionSummary(filter: $filter)\n  }\n"): (typeof documents)["\n  query GetQuestionSummary($filter: QuestionSummaryInput!) {\n    questionSummary(filter: $filter)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuestionWithStats($id: UUID!) {\n    question(id: $id) {\n      id\n      primaryQuestion\n      avgScore\n      avgMessageDepth\n      totalInsights\n      totalGrowthSignals\n      totalRiskSignals\n      totalUnreadInsights\n      tags {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetQuestionWithStats($id: UUID!) {\n    question(id: $id) {\n      id\n      primaryQuestion\n      avgScore\n      avgMessageDepth\n      totalInsights\n      totalGrowthSignals\n      totalRiskSignals\n      totalUnreadInsights\n      tags {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuestionsWithStats($after: Cursor, $orderBy: QuestionOrder) {\n    questions(after: $after, orderBy: $orderBy) {\n      items {\n        id\n        primaryQuestion\n        avgScore\n        avgMessageDepth\n        totalInsights\n        totalGrowthSignals\n        totalRiskSignals\n        totalUnreadInsights\n        tags {\n          id\n          name\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetQuestionsWithStats($after: Cursor, $orderBy: QuestionOrder) {\n    questions(after: $after, orderBy: $orderBy) {\n      items {\n        id\n        primaryQuestion\n        avgScore\n        avgMessageDepth\n        totalInsights\n        totalGrowthSignals\n        totalRiskSignals\n        totalUnreadInsights\n        tags {\n          id\n          name\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuestions(\n    $after: Cursor\n    $filter: QuestionListFilterInput\n    $first: Int\n    $orderBy: QuestionOrder\n  ) {\n    listQuestions(\n      after: $after\n      first: $first\n      filter: $filter\n      orderBy: $orderBy\n    ) {\n      items {\n        id\n        primaryQuestion\n        tags {\n          id\n          name\n        }\n        ratings {\n          id\n          label\n          value\n        }\n        totalInsights\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetQuestions(\n    $after: Cursor\n    $filter: QuestionListFilterInput\n    $first: Int\n    $orderBy: QuestionOrder\n  ) {\n    listQuestions(\n      after: $after\n      first: $first\n      filter: $filter\n      orderBy: $orderBy\n    ) {\n      items {\n        id\n        primaryQuestion\n        tags {\n          id\n          name\n        }\n        ratings {\n          id\n          label\n          value\n        }\n        totalInsights\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUnreadInsights {\n    questions {\n      items {\n        id\n        totalUnreadInsights\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUnreadInsights {\n    questions {\n      items {\n        id\n        totalUnreadInsights\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUpgradeData($insightsCountInput: DashboardFilterInput) {\n    companyInfo {\n      id\n      stripeCustomerId\n      insightsCount(filter: $insightsCountInput)\n      planDetails {\n        planCode\n        totalAllowedInsights\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUpgradeData($insightsCountInput: DashboardFilterInput) {\n    companyInfo {\n      id\n      stripeCustomerId\n      insightsCount(filter: $insightsCountInput)\n      planDetails {\n        planCode\n        totalAllowedInsights\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserManagementData(\n    $after: Cursor\n    $before: Cursor\n    $first: Int\n    $last: Int\n  ) {\n    invitations(after: $after, before: $before, first: $first, last: $last) {\n      items {\n        id\n        email\n      }\n    }\n    users(after: $after, before: $before, first: $first, last: $last) {\n      items {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUserManagementData(\n    $after: Cursor\n    $before: Cursor\n    $first: Int\n    $last: Int\n  ) {\n    invitations(after: $after, before: $before, first: $first, last: $last) {\n      items {\n        id\n        email\n      }\n    }\n    users(after: $after, before: $before, first: $first, last: $last) {\n      items {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InviteUser($input: InviteInput!) {\n    inviteUser(input: $input) {\n      id\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation InviteUser($input: InviteInput!) {\n    inviteUser(input: $input) {\n      id\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RefreshCompanyInfo {\n    refreshCompanyInfo {\n      id\n      name\n      category\n      description\n      website\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RefreshCompanyInfo {\n    refreshCompanyInfo {\n      id\n      name\n      category\n      description\n      website\n      productServices {\n        id\n        name\n        benefits\n        features\n        uniqueSellingPoints\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResendInvitation($id: ID!) {\n    resendInvitation(id: $id) {\n      id\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation ResendInvitation($id: ID!) {\n    resendInvitation(id: $id) {\n      id\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RevokeInvitation($id: ID!) {\n    revokeInvitation(id: $id)\n  }\n"): (typeof documents)["\n  mutation RevokeInvitation($id: ID!) {\n    revokeInvitation(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateCompanyInfo($input: OrganizationUpdateInput!) {\n    updateCompanyInfo(input: $input) {\n      id\n      category\n      description\n      name\n      productServices {\n        benefits\n        features\n        id\n        name\n        uniqueSellingPoints\n      }\n      website\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCompanyInfo($input: OrganizationUpdateInput!) {\n    updateCompanyInfo(input: $input) {\n      id\n      category\n      description\n      name\n      productServices {\n        benefits\n        features\n        id\n        name\n        uniqueSellingPoints\n      }\n      website\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateInsight($input: UpdateInsightInput!) {\n    updateInsight(input: $input) {\n      id\n      followup\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateInsight($input: UpdateInsightInput!) {\n    updateInsight(input: $input) {\n      id\n      followup\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateInsights($input: UpdateInsightsInput!) {\n    updateInsights(input: $input)\n  }\n"): (typeof documents)["\n  mutation UpdateInsights($input: UpdateInsightsInput!) {\n    updateInsights(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateQuestion($input: QuestionUpdateInput!) {\n    updateQuestion(input: $input) {\n      id\n      primaryQuestion\n      ratings {\n        id\n        label\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateQuestion($input: QuestionUpdateInput!) {\n    updateQuestion(input: $input) {\n      id\n      primaryQuestion\n      ratings {\n        id\n        label\n        value\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;